import { arciform, akrobat } from "../../fonts";
import { saveAs } from "file-saver";
import {
  setEmissionDate,
  setEventText,
  setImage,
  setName,
  setEventTitle,
  setDevidosFins,
} from "../generatePdf";
import { jsPDF } from "jspdf";
import JSZip from "jszip";
import firebase, { storage } from "../firebase";

const uploadFile = (storageRef, name, tituloEvento, file) => {
  var pdfRef = storageRef.child(
    `certificado_${name.toLocaleLowerCase()}_${tituloEvento.toLocaleLowerCase()}.pdf`
  );

  const downloadURL = pdfRef
    .put(file)
    .then((pdf) => pdf.ref.getDownloadURL().then((url) => url));
  return downloadURL;
};

const fileUpload = (file, name, email, tituloEvento, generatedPersons) => {
  var storageRef = storage.ref();

  const pdfRef = storageRef
    .child(
      `certificado_${name.toLocaleLowerCase()}_${tituloEvento.toLocaleLowerCase()}.pdf`
    )
    .put(file);

  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  pdfRef.on(
    "state_changed",
    function (snapshot) {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("Upload is " + progress + "% done");
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log("Upload is paused");
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log("Upload is running");
          break;
      }
    },
    function (error) {
      // Handle unsuccessful uploads
    },
    function () {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      pdfRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
        console.log("File available at", downloadURL);
        generatedPersons({
          name: name,
          email: email,
          downloadURL: downloadURL,
        });
      });
    }
  );
};

var callAddFont = function () {
  this.addFileToVFS("arciform-normal.ttf", arciform);
  this.addFont("arciform-normal.ttf", "arciform", "normal");
  this.addFileToVFS("akrobat-normal.ttf", akrobat);
  this.addFont("akrobat-normal.ttf", "akrobat", "normal");
};

const checkEmptyValues = (certificateInfos) =>
  Object.values(certificateInfos).some((item) => item.length === 0);
function salvar(
  storageRef,
  setLoading,
  certificado,
  certificateInfos,
  generatedPersons,
) {
  const nameArray = certificateInfos.listaNomes.split(",");
  const emailArray = certificateInfos.listaEmails.split(",");
  const hasEmptyValues = checkEmptyValues(certificateInfos);
  console.log(hasEmptyValues);
  jsPDF.API.events.push(["addFonts", callAddFont]);

  if (!hasEmptyValues) {
    setLoading(true);
    var zip = new JSZip();

    nameArray.map((name, index) => {
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [22.46, 15.88],
      });

      console.log({certificado})
      setImage(doc, certificado);
      setName(doc, name);
      setEventText(doc, certificateInfos.texto);
      setEmissionDate(doc, certificateInfos.dataEmissao);
      setDevidosFins(doc, certificateInfos.preName);
      setEventTitle(doc, certificateInfos.titulo, certificateInfos.participou);
      // storage.put(doc.output("blob"));
      const file = doc.output("blob");
      fileUpload(
        file,
        name,
        emailArray[index],
        certificateInfos.titulo,
        generatedPersons
      );

      //upload file to firebase store
      //   const downloadURL = uploadFile(
      //     storageRef,
      //     name,
      //     certificateInfos.titulo,
      //     file
      //   ).then((link) => {
      //     console.log(link);
      //     let person = {
      //       name: name,
      //       email: emailArray[index],
      //       downloadURL: link,
      //     };
      //     console.log(person);
      //     generatedPersons((prev) => [...prev, link]);

      //     return link;
      //   });

      zip.file(
        `certificado_${name.toLocaleLowerCase()}_${certificateInfos.titulo.toLocaleLowerCase()}.pdf`,
        file
      );
    });

    // return doc.save(nome + ".pdf")
    zip.generateAsync({ type: "blob" }).then(function (content) {
      const timeNow = new Date().getTime() / 1000 | 0;
      saveAs(content, `${timeNow}_certificados.zip`);
    });

    return setLoading(false);
  }
}
export { uploadFile, callAddFont, salvar };
