import React, { useState, useMemo } from "react";
import "./App.css";
import { Button, LinearProgress, TextField } from "@material-ui/core";
import certificado from "./certificado.png";
import { storage } from "./services/firebase";
import { salvar } from "./services/helpers";
import { sendEmail } from "./services/emailjs";
function App() {
  // Create a root reference
  var storageRef = storage.ref();
  const [loading, setLoading] = useState(false);
  const [certificateInfo, setCertificateInfo] = useState({
    preName: "A COGNITA Desenvolvimento Profissional confere a",
    participou: "o presente certificado referente a sua participação no Treinamento",
    listaNomes: "",
    listaEmails: "",
    titulo: "Titulo do evento",
    texto: "Texto descrevendo quantidade de horas e colaboradores",
    dataEmissao: `Recife, ${new Date().getUTCDate()} de ${new Date().getMonth()} de ${new Date().getFullYear()}`,
  });

  const [customBackground, setCustomBackground] = useState(null);
  const [useCustomBg, setUseCustomBg] = useState(false);


  const [generatedPersons, setGeneratedPersons] = useState([]);
  const [sentEmail, setSentEmail] = useState([]);
  const [totalPersons, setTotalPersons] = useState([]);

  const [errors, setErrors] = useState([]);
  const handleErrors = (personError) => {
    setErrors((prev) => [...prev, personError]);
  };
  

  const handleUseCustomBg = () => {
    setUseCustomBg(!useCustomBg);
  }

  const countGeneratedPersons = useMemo(
    () => generatedPersons && generatedPersons.length,
    [generatedPersons]
  );

  
  const handleEmailSend = () => {
    if (generatedPersons.length > 0)
      sendEmail(generatedPersons, certificateInfo.titulo, handleErrors);
  };

  const countTotal = useMemo(() => {
    const total =
      certificateInfo && certificateInfo.listaNomes.split(",").length;
    return total > 0 && ((countGeneratedPersons / total) * 100).toFixed(0);
  }, [certificateInfo.listaNomes, countGeneratedPersons]);

  const countErrors = useMemo(() => errors && errors.length, [errors]);

  const handlePersons = (value) => {
    setGeneratedPersons((prev) => [...prev, value]);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCertificateInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toSave = () => {
      const background = useCustomBg ? customBackground : certificado;
      return salvar(storageRef, setLoading, background, certificateInfo, handlePersons);

  };


  const handleFileSelect = (e) => {
    const value = e.target.files[0];
    let reader = new FileReader()
    reader.readAsDataURL(value)
    reader.onload = () => {
      setCustomBackground(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    }
    // const value = e.target.value;
    
    // ${event.target.files[0].name}
  }
  return (
    <>
    <div>
        <h1>Gerador de Certificados</h1>
        {/* <input type="checkbox" onClick={handleUseCustomBg} /> */}
        <div className="useCustomBg">
<label>Usar custom background</label>
        <input type="checkbox" className="useCustomBg__checkbox" onChange={() => setUseCustomBg(!useCustomBg)} value={useCustomBg}/>
        </div>
{useCustomBg &&
        <input type="file" accept="image/*" name="customBg" onChange={handleFileSelect} />}
      </div>
      <form className="App">
        <TextField
          label="Lista de Nomes"
          name="listaNomes"
          required
          helperText="Daniel Carvalho, Joao Silva, Maria da Silva"
          fullWidth
          vallue={certificateInfo.listaNomes}
          onChange={(e) => handleChange(e)}
        ></TextField>
        <br />
        <TextField
          label="Lista de emails"
          name="listaEmails"
          required
          helperText="email_1@gmail.com, email_2@gmail.com, email_3@gmail.com"
          fullWidth
          vallue={certificateInfo.listaEmails}
          onChange={(e) => handleChange(e)}
        ></TextField>
         <br />
        <TextField
          label="Pre-nome"
          name="preName"
          helperText="A COGNITA Desenvolvimento Profissional confere a"
          required
          fullWidth
          value={certificateInfo.preName}
          onChange={handleChange}
        ></TextField>
        <br />
        <TextField
          label="Descricao do tipo de evento"
          name="participou"
          helperText="o presente certificado referente a sua participação no curso/oficina/workshop"
          required
          fullWidth
          value={certificateInfo.participou}
          onChange={handleChange}
        ></TextField>
        <br />
        <TextField
          label="Titulo"
          name="titulo"
          helperText="OFICINA NOME DA OFICINA ETC"
          required
          fullWidth
          value={certificateInfo.titulo}
          onChange={handleChange}
        ></TextField>
        <br />
        <TextField
          label="Texto"
          name="texto"
          helperText="concluído em DD e DD de MêsPorExtenso de AAAA, com duração de HH horas."
          required
          fullWidth
          value={certificateInfo.texto}
          onChange={handleChange}
        ></TextField>
        <br />
        <TextField
          label="Data de emissão"
          name="dataEmissao"
          required
          value={certificateInfo.dataEmissao}
          helperText="Cidade, DD de MêsPorExtenso de AAAA"
          fullWidth
          onChange={handleChange}
        ></TextField>
        <br />

        
        <br />
        <div className="buttonSubmit">
          <Button
            key={12312313}
            onClick={() => toSave()}
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
          >
            GERAR CERTIFICADOS
          </Button>
        </div>
      </form>
      <div className="linearBar">
        <LinearProgress
          key={123456765432}
          style={{ width: "70%", height: 20 }}
          variant="determinate"
          value={countTotal}
        />
        <div className="statusBar">Upload: {countTotal}%</div>
      </div>
      <div className="sendEmail">
        <Button
          key={222}
          onClick={handleEmailSend}
          fullWidth
          type="button"
          variant="contained"
          color="primary"
        >
          ENVIAR EMAIL
        </Button>
      </div>
      {/* <div className="linearBar">
        <LinearProgress
          key={12312903}
          style={{ width: "70%", height: 20 }}
          variant="determinate"
          value={countTotal}
        />
        <div className="statusBar">Enviado: {countTotal}%</div>
      </div> */}

      
      

      {countErrors > 0 && (
        <div className="errorWraper">
          <h3>Erros:</h3>
          {errors.map((err) => (
            <div className="errorItem">
              <div>{err.name}</div>
              <div>{err.email}</div>
              <a href={err.downloadURL}>link</a>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default App;
