// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

import "firebase/storage";

// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig = {
  apiKey: "AIzaSyD7E7cfGj-kSxyqC7ohXS8EJKLC3bLaSrQ",
  authDomain: "cognita-certificado.firebaseapp.com",
  projectId: "cognita-certificado",
  storageBucket: "cognita-certificado.appspot.com",
  messagingSenderId: "476214968651",
  appId: "1:476214968651:web:174f89414a2bc4df08a8ce",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const  storage = firebase.storage();
export default firebase

// Get a reference to the storage service, which is used to create references in your storage bucket


