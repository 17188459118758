import emailjs from "emailjs-com";
const SERVICE_ID = "service_aoqa6nf";
const TEMPLATE_ID = "template_7ok84pc";
const USER_ID = "user_piQ9Pab91XrLAy5dRfviv";

const sendEmail = (personArray, eventName, handleErrors) => {
  personArray.map((person) => {
    emailjs
      .send(
        SERVICE_ID,
        TEMPLATE_ID,
        {
          to_name: person.name,
          event_name: eventName,
          downloadURL: person.downloadURL,
          to_email: person.email,
          from_name: "COGNITA",
        },
        USER_ID
      )
      .then((response) => console.log(response)).catch(err=> handleErrors(person));
  });
};

export { sendEmail };
