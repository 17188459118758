const centro = 22.46 / 2;
const centroV = 15.88 / 2;
const NAME_1_LINE_WIDTH = 15.592499999999998;
const TITLE_1_LINE_WIDTH = 15.939;

function setDevidosFins(doc, preNameText) {
  doc.setFontSize(40);
  doc.setTextColor("#494647");
    return doc.text(preNameText, centro, centroV - 4, {
    align: "center",
    maxWidth: 16,
  });
}
function setImage(documento, certificado) {
  return documento.addImage(certificado, "png", 0, 0, 22.46, 15.88, "FAST");
}
function setName(documento, name) {
  const nameFixed = name.replace(/\b\w/g, (l) => l.toUpperCase()).toUpperCase();
  documento.setFontSize(90);
  documento.setFont("arciform");
  documento.setTextColor("#615c5e");
  const dimentions = documento.getTextDimensions(nameFixed);
  const textWidth = dimentions.w;
  
  const linesCount = ((textWidth-0.5)/NAME_1_LINE_WIDTH);
  if (linesCount>1.5) documento.setFontSize(80 - (10*linesCount));
  console.log("dimentions", dimentions,"linesCount",linesCount)
  const fixPositionV = linesCount < 3 ? (1.8 + (0.5*linesCount)) : 3;
  const centroVFixed = centroV - fixPositionV;
  documento.text(
    nameFixed,
    centro,
    centroVFixed,
    {
      align: "center",
      maxWidth: 16,
      lineHeightFactor: 0.9,
    }
  );
  
  return documento.text;
}
// doc.text(nome.toLocaleUpperCase(), centro, 5.8, { align: "center", maxWidth: 12 });

//text of the event
function setEventText(documento, texto) {
  documento.setFontSize(34);
  documento.setFont("akrobat");
  documento.setTextColor("#494647");

  documento.text(texto, centro, 10.2, {
    align: "center",
    maxWidth: 16,
  });
}

//text of the event
function setEventTitle(documento, tituloEvento, participou) {
  const fixedTitle = tituloEvento.toUpperCase()
  documento.setFontSize(44);
  documento.setFont("arciform");
  documento.setTextColor("#494647");
  const dimentions = documento.getTextDimensions(fixedTitle);
  const textWidth = dimentions.w;
  const linesCount = ((textWidth-1)/TITLE_1_LINE_WIDTH) | 0;
  const centroVFixed = 9 - (0.5*linesCount);
  
  documento.text(fixedTitle, centro, centroVFixed, {
    align: "center",
    maxWidth: 16,
  });
  documento.setFont("akrobat");
  documento.setFontSize(34);
  documento.text(participou, centro, 7.4, {
    align: "center",
    maxWidth: 16,
  });
}

//date of emission
function setEmissionDate(documento, emissao) {
  documento.setFontSize(40);
  documento.setFont("akrobat");
  documento.setTextColor("#494647");
  // documento.text(emissao, centro, 12.5, { align: "center", maxWidth: 16 });
  documento.text(emissao, 19, 12.3, { align: "center", maxWidth: 16 });
}

export {
  setEmissionDate,
  setEventText,
  setImage,
  setName,
  setEventTitle,
  setDevidosFins,
};
